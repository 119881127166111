import * as React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { VotacionElementCompleto } from "../../components/cards";
import { getCongresistasPublic, getLogDelegatesSeatNumberActiveConferencePublic, getGruposBancadaPublic, getVotosVotacionActivaPublic, getSeatCoordinateDiagramaPublic, getOpcionesSV4Public } from "../../services/juntanominadora";
import '../../styles/diagrama.css';
import '../../styles/animations.css';
import { getUltimaVotacionPublic, putOpciones } from "../../services/api";

const Diagrama: React.FC<{onlyAsistencia?:boolean}> = ({onlyAsistencia}) => {
    const [congresistas, setCongresistas] = useState<any[]>([]);
    const [gruposBancada, setGruposBancada] = useState<any[]>([]);
    const [logDelegatesSeatNumber, setLogDelegatesSeatNumber] = useState<any[]>([]);
    const [SVDelegatesVotaron, setSVDelegatesVotaron] = useState<any[]>([]);
    //const [seatNumbers, setSeatNumbers] = useState<any[]>([]);
    const [coordenadas, setCoordenadas] = useState<any[]>([]);
    const [congresistasConectados, setcongresistasConectados] = useState<number>(0);
    const [ultimaVotacion, setUltimaVotacion] = useState<any[]>([]);
    const [votosSI, setvotosSI] = useState<Number>(0);
    const [votosNO, setvotosNO] = useState<Number>(0);
    const [votosABS, setvotosABS] = useState<Number>(0);
    const [votosNV, setvotosNV] = useState<Number>(0);
    const [agendaTitleVotacionShure, setagendaTitleVotacionShure] = useState<string>("");
    const [diagramaShowUltimaVotaicon, setdiagramaShowUltimaVotaicon] = useState<boolean>(false);
    const [ultimasVotaciones, setultimasVotaciones] = useState<any[]>([]);
    const [idVotacionSeleccionada, setidVotacionSeleccionada] = useState<number>(0);
    const [dateState, setDateState] = useState(moment());

    // Config
    /*
    const dia = dateState.format("dddd").toUpperCase();
    const diaNumber = dateState.format("DD");
    const mes = dateState.format("MMMM").toUpperCase();
    const ano = dateState.format("YYYY");
    const hora = dateState.format("hh:mm:ss");
    */

    // Funciones
    const toggleShowUltimaVotacion = async () =>{
        if(!onlyAsistencia) {
            setagendaTitleVotacionShure("");
            await putOpciones("diagrama-show-ultimavotacion", diagramaShowUltimaVotaicon ? "0" : "1");
        }
    }

    const onScroolSelect = () =>{
        console.dir("scrool");
        const sel:any = this;
        const lasto = sel!.find('option:last');
        const s = sel.position().top + sel.height();
        const o = lasto.height() + lasto.position().top - 4;
        setTimeout(function(){
            if(o < s) {
                console.log('We need AJAX!');
            }
        }, 2200);
    }

    const onSelectVotacionChange = (event:any) => {
        setagendaTitleVotacionShure("");
        const value = event.target.value;
        setidVotacionSeleccionada(value);
    }

    // useEffect
    useEffect(() => {
        // Obtener todos los congresistas shure
        if(congresistas.length === 0){
            getCongresistasPublic().then((v) => {
                if (v.data.successed) {
                    setCongresistas(v.data.content);
                };
            });
        }

        if(gruposBancada.length === 0){
            getGruposBancadaPublic().then((v) => {
                if (v.data.successed) {
                    setGruposBancada(v.data.content);
                };
            });
        }

        // Obtener Coordenadas Complete
        if(coordenadas.length === 0){
            getSeatCoordinateDiagramaPublic().then((v) => {
                if (v.data.successed) {
                    setCoordenadas(v.data.content);
                };
            });
        }

        const inv = setInterval(async () => {            
            if(!diagramaShowUltimaVotaicon){
                setDateState(moment());
            }

            if(!onlyAsistencia) {
                await getOpcionesSV4Public().then((v) => {
                    if (v.data.successed) {
                        const opciones = v.data.content;
                        const opcion = opciones.find((o:any) => o.name === "diagrama-show-ultimavotacion");
                        setdiagramaShowUltimaVotaicon(opcion ? opcion.value === "1" : false);
                    };
                });
            }

            if(diagramaShowUltimaVotaicon){
                // Obtener Votos Shure
                await getUltimaVotacionPublic(idVotacionSeleccionada).then((v) => {
                    if (v.data.successed) {
                        let agendaTitle = "";

                        // Set Ultima Votacion
                        const ultimaVotacion = v.data.content[0];
                        setUltimaVotacion(ultimaVotacion);

                        // Ultima Votacion Contar Votos
                        if(v.data.content[1].length){
                            agendaTitle = v.data.content[1][0].name;
                        }

                        if(agendaTitleVotacionShure !== agendaTitle) {
                            let votosSI = 0;
                            let votosNO = 0;
                            let votosABS = 0;
                            let votosNV = 0;
                            ultimaVotacion.forEach((vr:any) => {
                                if(vr.Vote1 === 1) votosSI++
                                else if(vr.Vote3 === 1) votosNO++
                                else if(vr.Vote2 === 1) votosABS++
                                else votosNV++
                            });
                            setvotosSI(votosSI);
                            setvotosNO(votosNO);
                            setvotosABS(votosABS);
                            setvotosNV(votosNV);
                            
                            // Set Fecha de votacion
                            if(ultimaVotacion.length) {
                                setDateState(moment(ultimaVotacion[0].Date.replace('Z','')));
                            }
                        }
                        
                        // Set Agenda Title
                        setagendaTitleVotacionShure(agendaTitle);

                        // Set Ultimas N Votaciones
                        if(v.data.content[2].length){
                            setultimasVotaciones(v.data.content[2]);
                        }
                    };
                });
            }
            
            // Obtener LogDelegate With Seat Number de Conferencia Activa
            await getLogDelegatesSeatNumberActiveConferencePublic().then((v) => {
                if (v.data.successed) {
                    setLogDelegatesSeatNumber(v.data.content);
                    setcongresistasConectados(v.data.content.length);
                };
            });

            // Obtener Votos Votacion Activa Magistrados DB: MagistradosCSJ
            /*
            await getVotosVotacionActivaPublic().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.content;
                    //const seatNumbers = Array.from(new Set(votes.map((item: any) => item.seatnumber)))
                    //setSeatNumbers(seatNumbers);
                    const SVDelegatesVotaron = Array.from(new Set(votes.map((item: any) => item.user_id)))
                    setSVDelegatesVotaron(SVDelegatesVotaron);
                };
            });
            */

        }, 2000);
        return () => {clearInterval(inv);}
    }, [congresistas, gruposBancada, coordenadas, diagramaShowUltimaVotaicon, onlyAsistencia, idVotacionSeleccionada, agendaTitleVotacionShure]);

    return (
        <div className={"container-fluid diagramaBackground"}>
            <img className="bgcurules" alt="Background" src="images/diagrama.png"/>
            <div className="logoContainer" onClick={toggleShowUltimaVotacion}>
                <img draggable={false}  alt="Congreso Nacional de Honduras" src={`images/logo-dorado.png`} width="100%" />
            </div>
            <div className="cardsContainer">
            {coordenadas.map((coor: any, index) => {
                let logDelegate:any[];
                let congresista:any[];

                if(diagramaShowUltimaVotaicon){
                    logDelegate = ultimaVotacion.filter((vr:any) => { return vr.SeatNumber === coor.SeatNumber });
                    congresista = logDelegate.length ? congresistas.filter((c:any) => { return c.delegate_id === logDelegate[0].LogDelegate_id}) : [];
                } else {
                    logDelegate = logDelegatesSeatNumber.filter((ld:any) => { return ld.SeatNumber === coor.SeatNumber });
                    congresista = logDelegate.length ? congresistas.filter((c:any) => { return c.delegate_id === logDelegate[0].delegate_id}) : [];
                }
                
                // voto del shure
                const voto = congresista.length ? ultimaVotacion.filter((vr:any) => { return vr.LogDelegate_id === congresista[0].delegate_id }) : [];
                // voto sistema votacion csj
                //const delegateVoted = congresista.length ? SVDelegatesVotaron.filter((delegate:any) => { return delegate === `${congresista[0].delegate_id}` }) : [];
                //const votedCSJ = delegateVoted.length ? true : false;

                const longerName = congresista.length ? Math.max(congresista[0].FirstName.split(" ")[0].length, congresista[0].LastName.split(" ")[0].length) : 0;
                let caracteresExtras = longerName - 8;
                if(longerName === 8){
                    caracteresExtras = 1;
                }
                const fonSizePercent = caracteresExtras > 0 ? 0.00755 - (caracteresExtras * 0.00052) : 0.00755; // 0.00625

                const nombreCompleto = congresista.length ? `${congresista[0].FirstName} ${congresista[0].LastName}`.toUpperCase() : "";
                let congresistaName = congresista.length ? `${congresista[0].FirstName.split(" ")[0]} ${congresista[0].LastName.split(" ")[0]}`.toUpperCase() : ""; //coor.SeatNumber;

                const bancada = congresista.length ? gruposBancada.filter((b:any) => { return b.group_name === congresista[0].bancada.toUpperCase().trim() }) : [];

                const groupColor1 = bancada.length ?  bancada[0].group_color1 : '#9a9a9a';
                const groupColor2 = bancada.length ?  bancada[0].group_color2 : '#9a9a9a';

                // Personalizaciones
                if(nombreCompleto === 'JOSUE FABRICIO CARBAJAL SANDOVAL')
                    congresistaName = 'FABRICIO SANDOVAL';
                if(nombreCompleto === 'JARI DIXON HERRERA HERNANDEZ')
                    congresistaName = 'JARI DIXON';
                if(nombreCompleto === 'LUZ ANGELICA SMITH MEJIA')
                    congresistaName = 'ANGELICA SMITH';                
                if(nombreCompleto === 'MARIA ISABEL VALDEZ BRIONES')
                    congresistaName = 'ISABEL VALDEZ';

                return (
                    <React.Fragment key={coor.SeatNumber} >
                        <VotacionElementCompleto expand showUltimaVotacion={diagramaShowUltimaVotaicon} nombreCompleto={nombreCompleto} /*votedCSJ={votedCSJ}*/ voto={voto} top={coor.YCoordinate} left={coor.XCoordinate} id={coor.SeatNumber} congresistaName={congresistaName} fonSizePercent={fonSizePercent} groupColor1={groupColor1} groupColor2={groupColor2}/>
                    </React.Fragment>
                )
            }) }
            </div>

            <div className="div-fecha">
                <span>{dateState.format("dddd").toUpperCase()} {dateState.format("DD")} {dateState.format("MMMM").toUpperCase()} {dateState.format("YYYY")}</span>
            </div>

            <div className="div-hora">
                <span>{dateState.format("hh:mm A")}</span>
            </div>
            
            <div className="div-votacion-title">
                {!diagramaShowUltimaVotaicon &&
                    <h2 className="main-title fs-2vw lh-5">SESIÓN ORDINARIA</h2>
                }

                {diagramaShowUltimaVotaicon &&
                    <React.Fragment>
                        <div className="title-votacion">VOTACIÓN: {agendaTitleVotacionShure === "" ? <div className="dot-flashing"></div> : agendaTitleVotacionShure} </div>
                        <select className="select-votacion" style={{border: "none", background: "none", color: "#afafb6",}} onChange={onSelectVotacionChange}>
                            {ultimasVotaciones.map(votacion => {
                                return (<option key={votacion.Id} value={votacion.Id}>{votacion.name}</option>);
                            })}
                        </select>
                    </React.Fragment>
                }
            </div>

            <div className="leyenda">
                {!diagramaShowUltimaVotaicon &&
                    <React.Fragment>
                        <h2 className="main-title">CONGRESISTAS PRESENTES</h2>
                        <span className="congresistas-conectados-number">{congresistasConectados}</span>
                    </React.Fragment>
                }
                {diagramaShowUltimaVotaicon &&
                    <React.Fragment>
                        <table className="tableLeyenda">
                            <tbody>
                            <tr>
                                <td></td>
                                <td style={{background: "none"}}>
                                    RESULTADOS
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="circleResults" style={{background: "#00ff00",}}/>
                                </td>
                                <td>SI</td>
                                <td>{votosSI.toString()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="circleResults" style={{background: "#f90000",}}/>
                                </td>
                                <td>NO</td>
                                <td>{votosNO.toString()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="circleResults" style={{background: "#f9fd00",}}/>
                                </td>
                                <td>ABSTENCIÓN</td>
                                <td>{votosABS.toString()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="circleResults" style={{background: "#000000",}}/>
                                </td>
                                <td>NO VOTARON</td>
                                <td>{votosNV.toString()}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="circleResults" style={{background: "#ffffff",}}/>
                                </td>
                                <td>PRESENTES</td>
                                <td>{ultimaVotacion.length}</td>
                            </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                }
            </div>
            
            <div className="custom-select" style={{width: "0.96vw", height: "0.96vh", position: "absolute", top: "0.4vh", left: "0.2vw"}}>
            </div>
        </div>
    )
};
export default Diagrama;

